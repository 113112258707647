footer {
  background: var(--color-amarillo);
  padding: 1rem 0;
  text-align: center;
  font-size: 0.9rem;
  border-top: 8px solid var(--color-amarillo-variant);
}
footer a {
  color: var(--color-rojo-variant);
}

.footer__logo {
  margin-bottom: 0.5rem;
  display: inline-block;
  width: 80px;
  height: 80px;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto 2rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.footer__copyright {
  margin-bottom: 1rem;
  color: #c99000;
}

/* Phones */

@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    gap: 1rem;
  }
}
