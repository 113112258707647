.bio {
  background-color: var(--color-black);
  height: 100vh;
}

.bio__container {
  background-image: url("../../assets/arturo_back.jpg");
  display: grid;
  grid-template-rows: 2fr 1fr 1fr;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 100vh;
}

.bio__title {
  display: grid;
  height: 100%;
  place-items: center;
  margin: 7rem 3rem 2rem;
}

.bio__title-image {
  display: block;
  margin-left: auto;
}

.parrafo__bio {
  margin: 2rem 3rem 0;
  text-align: right;
  color: var(--color-light);
  font-size: 0.8rem;
  padding-left: 20%;
}

.botones__bio {
  display: grid;
  height: 100%;
  place-items: center;
  margin: 0 3rem 3rem;
  z-index: -8;
}

.botones {
  display: block;
  margin-left: auto;
}

.bio__pleca {
  display: flex;
  background-color: var(--color-amarillo);
  border-top: 8px solid var(--color-amarillo-variant);
  height: 5rem;
  align-items: center;
  overflow-x: hidden;
  max-width: 100%;
  width: 100vw;
  position: relative;
}

.track__bio {
  position: absolute;
  white-space: nowrap;
}

.marquee_bio {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 900;
  color: var(--color-rojo);
  font-size: 1.6rem;
}

.marquee_bio-v {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 900;
  color: var(--color-black);
  font-size: 1.6rem;
}

/* Tablets */

@media screen and (max-width: 1024px) {
  .parrafo__bio {
    padding-left: 20%;
  }
}

/* Phones */

@media screen and (max-width: 600px) {
  .bio__container {
    grid-template-rows: 1fr 1fr 1fr;
    height: 100vh;
  }

  .bio__title {
    margin: 4rem 2rem 1rem;
    padding: 1rem;
  }

  .bio__title-image {
    width: 40%;
  }

  .parrafo__bio {
    margin: 1rem 3rem 0;
    padding-left: 1%;
  }

  .botones__bio {
    height: 80%;
    place-items: center;
    margin: 0 3rem 3rem;
    z-index: -8;
  }

  .botones {
    display: block;
    margin-left: auto;
  }

  .btn {
    padding: 0.7rem 0.7rem;
    font-size: 0.7rem;
  }

  .bio__pleca {
    border-top: 5px solid var(--color-amarillo-variant);
    height: 3rem;
  }

  .marquee_bio {
    font-size: 1.2rem;
  }

  .marquee_bio-v {
    font-size: 1.2rem;
  }
}

/*landscape*/

@media screen and (max-device-width: 1000px) and (orientation: landscape) {
  nav,
  nav:hover {
    background: transparent;
    width: max-content;
    display: block;
    padding: 1rem 1rem;
    z-index: 2;
    position: fixed;
    left: 8%;
    transform: translateX(-8%);
    top: 2rem;
    display: flex;
    opacity: 1;
  }

  .bio__container {
    grid-template-rows: repeat(3, 1fr);
    height: 100vh;
  }

  .bio__title {
    margin: 2.5rem 3rem 1rem;
    padding: 0.1rem;
    height: 50%;
  }

  .bio__title-image {
    width: 20%;
  }

  .parrafo__bio {
    margin: 1rem 3rem 0;
    text-align: right;
    color: var(--color-light);
    font-size: 0.8rem;
    padding-left: 2%;
  }

  .botones {
    display: none;
  }

  .bio__pleca {
    border-top: 5px solid var(--color-amarillo-variant);
    height: 3rem;
  }

  .marquee_bio {
    font-size: 1.2rem;
  }

  .marquee_bio-v {
    font-size: 1.2rem;
  }
}
