.social {
  background-color: var(--color-amarillo);
  height: 100vh;
}

.social__container {
  background-image: url("../../assets/charly_back.jpg");
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  height: 100vh;
}

.social__title {
  display: grid;
  height: 100%;
  place-items: center;
  margin: 6rem 3rem 2rem;
}

.social__title-image {
  overflow: hidden;
  display: block;
  margin-right: auto;
}

.redes__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  width: 1000px;
  margin-right: auto;
  padding: 3rem;
}

.redes {
  display: grid;
  height: 100%;
  place-items: center;
}

.redes-image {
  overflow: hidden;
  display: block;
  cursor: pointer;
}

.social__pleca {
  display: flex;
  background-color: var(--color-black);
  border-top: 8px solid #1c2123;
  height: 5rem;
  align-items: center;
  overflow-x: hidden;
  max-width: 100%;
  width: 100vw;
  position: relative;
}

.track__redes {
  position: absolute;
  white-space: nowrap;
}

.marquee__redes {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 900;
  color: var(--color-amarillo);
  font-size: 1.6rem;
}

.marquee__redes-v {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 900;
  color: var(--color-white);
  font-size: 1.6rem;
}

.marquee__redes-v:hover {
  color: var(--color-rojo);
}

/* Tablets */

@media screen and (max-width: 1024px) {
  .redes__container {
    grid-template-columns: repeat(2, 1fr);
    width: 55%;
    margin-right: auto;
  }
}

/* Phones */

@media screen and (max-width: 600px) {
  .social__title {
    margin: 5rem 1rem 1rem;
  }

  .social__title-image {
    display: block;
    margin: auto;
    width: 70%;
  }

  .redes__container {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin: auto;
  }

  .social__pleca {
    height: 3rem;
    border-top: 5px solid #1c2123;
  }

  .marquee__redes {
    font-size: 1.2rem;
  }

  .marquee__redes-v {
    font-size: 1.2rem;
  }
}

/*landscape*/

@media screen and (max-device-width: 1000px) and (orientation: landscape) {
  .social__title {
    margin: 2rem 3rem 0;
    height: 80%;
  }

  .social__title-image {
    width: 30%;
    margin-right: -20rem;
  }

  .redes__container {
    grid-template-columns: repeat(4, 1fr);
    width: 85%;
    margin: 1rem;
    padding: 1rem;
  }

  .social__pleca {
    border-top: 5px solid #1c2123;
    height: 3rem;
  }

  .marquee__redes {
    font-size: 1.2rem;
  }

  .marquee__redes-v {
    font-size: 1.2rem;
  }
}
