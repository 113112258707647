@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,700;0,900;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #032b42;
  --color-amarillo: #ffe026;
  --color-amarillo-variant: #f2c600;
  --color-rojo: #e52927;
  --color-rojo-variant: #9d0d07;
  --color-black: #000000;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.8);
  --color-light-variant: rgba(255, 255, 255, 0.3);

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 85%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Montserrat", sans-serif;
  background: var(--color-black);
  color: var(--color-white);
  line-height: 1.5;
}

/* ESTILOS GENERALES */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}

h1 {
  font-size: 2rem;
}

section {
  margin-top: 0;
  height: 100vh;
}

section > h2,
section > h5 {
  text-align: right;
  color: var(--color-amarillo);
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-white);
  transition: var(--transition);
}

a:hover {
  color: var(--color-black);
}

.btn {
  width: 120px;
  display: grid;
  background: var(--color-black);
  color: var(--color-white);
  padding: 0.7rem 1rem;
  cursor: pointer;
  border: 1px solid var(--color-light-variant);
  transition: var(--transition);
  font-weight: 700;
}

.btn:hover {
  background: var(--color-black);
  color: var(--color-white);
  border: 1px solid var(--color-white);
  border-top: 6px solid var(--color-white);

  font-weight: 700;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* Tablets */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 0;
  }
}

/* Phones */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section {
    margin-top: 0;
  }
}
