.music {
  background-color: var(--color-amarillo);
  height: 100vh;
}

.music__container {
  background-image: url("../../assets/erick_back.jpg");
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  height: 100vh;
}

.music__title {
  display: grid;
  height: 100%;
  place-items: center;
  margin: 6rem 3rem 2rem;
}

.music__title-image {
  overflow: hidden;
  display: block;
  margin-right: auto;
}

.discos__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-right: auto;
  padding: 3rem;
  width: 800px;
}

.discos {
  background-image: url("../../assets/spotify.png");
  background-color: #1db954;
  background-size: cover;
  display: grid;
  height: max-content;
  width: -moz-fit-content;
  width: fit-content;
  place-items: center;
  opacity: 1;
  border-radius: 15rem;
}

.discos-image {
  overflow: hidden;
  display: block;
  margin: auto;
  border: 1px solid var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.discos-image:hover {
  overflow: hidden;
  display: block;
  margin: auto;
  transition: var(--transition);
  opacity: 0.1;
  cursor: pointer;
  border-radius: 15rem;
}

.music__pleca {
  display: flex;
  background-color: var(--color-rojo);
  border-top: 8px solid var(--color-rojo-variant);
  height: 5rem;
  align-items: center;
  overflow-x: hidden;
  max-width: 100%;
  width: 100vw;
  position: relative;
}

.track__discos {
  position: absolute;
  white-space: nowrap;
}

.marquee__discos {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 900;
  color: var(--color-amarillo);
  font-size: 1.6rem;
}

.marquee__discos-v {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 900;
  color: var(--color-white);
  font-size: 1.6rem;
}

/* Tablets */

@media screen and (max-width: 1024px) {
  .discos__container {
    width: 100vw;
  }
}

/* Phones */

@media screen and (max-width: 600px) {
  .music__title {
    margin: 4rem 1rem 1rem;
    padding: 1rem;
  }

  .music__title-image {
    display: block;
    margin: auto;
    width: 80%;
  }

  .discos__container {
    grid-template-columns: repeat(2, 1fr);
    width: 95%;
    margin: auto;
    margin-bottom: 2rem;
  }

  .music__pleca {
    border-top: 5px solid var(--color-rojo-variant);
    height: 3rem;
  }

  .marquee__discos {
    font-size: 1.2rem;
  }

  .marquee_discos-v {
    font-size: 1.2rem;
  }
}

/*landscape*/

@media screen and (max-device-width: 1000px) and (orientation: landscape) {
  nav,
  nav:hover {
    background: transparent;
    width: max-content;
    display: block;
    padding: 1rem 1rem;
    z-index: 2;
    position: fixed;
    left: 8%;
    transform: translateX(-8%);
    top: 2rem;
    display: flex;
    opacity: 1;
  }

  .music__title {
    margin: 2.5rem 3rem 1rem;

    height: 50%;
  }

  .music__title-image {
    width: 40%;
    margin-right: -20rem;
  }

  .discos__container {
    width: 60%;
    padding: 1rem;
    margin: 1rem;
  }

  .music__pleca {
    border-top: 5px solid var(--color-rojo-variant);
    height: 3rem;
  }

  .marquee__discos {
    font-size: 1.2rem;
  }

  .marquee_discos-v {
    font-size: 1.2rem;
  }
}
