header {
  background-image: url("../../assets/fondo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* SOCIALS */

.header__socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
  position: absolute;
  left: 0;
  bottom: 2rem;
  font-size: 1.2rem;
  z-index: 5;
  --color-black: var(--color-amarillo);
}

/* SCROLL */

.scroll__down {
  position: absolute;
  right: 0.7rem;
  bottom: 2rem;
  font-weight: 400;
  font-size: 1.3rem;
  z-index: 5;
  --color-black: var(--color-amarillo);
}

/* LOGO ANIMADO */

.logo {
  width: 18rem;
  position: absolute;
  left: calc(50% - 9rem);
  transform: translateX(-50%);
  top: calc(50% - -1rem);
  transform: translateY(-50%);
  z-index: 1;
}

/* CABEZA */

.cabeza {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 2;
  top: calc(50% - -2rem);
  transform: translateY(-50%);
}

/* MEDIA QUERIES Tablets */

@media screen and (max-width: 1024px) {
  header {
    height: 100vh;
  }
}

/* MEDIA QUERIES Phones */

@media screen and (max-width: 600px) {
  header {
    background-size: 100%;
    height: 100vh;
  }

  .header__socials {
    flex-direction: column;
  }

  /*  .header__socials,
    .scroll__down {
        display: none;
    }                      */
}
