nav {
  background: transparent;
  width: max-content;
  display: block;
  padding: 0.6rem 0.6rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 3rem;
  display: flex;
  gap: 0.6rem;
  border: 1px solid var(--color-light-variant);
  opacity: 0.5;
  transition: all 0.5s;
}

nav:hover {
  background: var(--color-black);
  width: max-content;
  display: block;
  padding: 0.9rem 1rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 2rem;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  border-top: 6px solid var(--color-white);
  opacity: 1;
  transition: all 0.5s;
}

nav a {
  background: transparent;
  padding: 0.2rem 0.6rem;
  border: 1px solid var(--color-white);
  display: flex;
  color: var(--color-white);
  font-size: 1.3rem;
  font-weight: 900;
}

nav a:hover {
  background: var(--color-amarillo);
  border: 1px solid var(--color-amarillo-variant);
}

nav a.active {
  background: var(--color-amarillo);
  color: var(--color-black);
  border: 1px solid var(--color-amarillo-variant);
}

nav a.active:hover {
  background: var(--color-amarillo);
}

/* MEDIA QUERIES Tablets */

@media screen and (max-width: 1024px) {
  nav {
    background: transparent;
    width: max-content;
    display: block;
    padding: 1rem 1rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 2rem;
    display: flex;
    opacity: 1;
  }
}

/* MEDIA QUERIES Phones */

@media screen and (max-width: 600px) {
  nav {
    background: transparent;
    width: max-content;
    display: block;
    padding: 1rem 1rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 2rem;
    display: flex;
    opacity: 1;
  }
}
