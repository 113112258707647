.video {
  background-color: var(--color-rojo);
  height: 100vh;
}

.video__container {
  background-image: url("../../assets/gus_back.jpg");
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 100vh;
}

.video__title {
  display: grid;
  height: 100%;
  place-items: center;
  margin: 6rem 3rem 2rem;
}

.video__title-image {
  display: block;
  margin-left: auto;
}

.videos__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 700px;
  margin-left: auto;
  padding: 3rem;
}

.videos {
  background-image: url("../../assets/youtube.png");
  background-color: #1db954;
  background-size: cover;
  display: grid;
  height: max-content;
  width: -moz-fit-content;
  width: fit-content;
  place-items: center;
  opacity: 1;
  border-radius: 15rem;
}

.videoshome {
  background-image: url("../../assets/home.png");
  background-color: #1db954;
  background-size: cover;
  display: grid;
  height: max-content;
  width: -moz-fit-content;
  width: fit-content;
  place-items: center;
  opacity: 1;
  border-radius: 15rem;
}

.videos-image {
  overflow: hidden;
  display: block;
  margin: auto;
  border: 1px solid var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.videos-image:hover {
  overflow: hidden;
  display: block;
  margin: auto;
  transition: var(--transition);
  opacity: 0.1;
  cursor: pointer;
  border-radius: 15rem;
}

.video__pleca {
  display: flex;
  background-color: var(--color-amarillo);
  border-top: 8px solid var(--color-amarillo-variant);
  height: 5rem;
  align-items: center;
  overflow-x: hidden;
  max-width: 100%;
  width: 100vw;
  position: relative;
}

.track__video {
  position: absolute;
  white-space: nowrap;
}

.marquee__video {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 900;
  color: var(--color-black);
  font-size: 1.6rem;
}

.marquee__video-v {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 900;
  color: var(--color-rojo);
  font-size: 1.6rem;
}

.marquee__video-v2 {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 900;
  color: var(--color-rojo-variant);
  font-size: 1.6rem;
}

/* Tablets */

@media screen and (max-width: 1024px) {
  .videos__container {
    width: 80%;
  }
}

/* Phones */

@media screen and (max-width: 600px) {
  .video__title {
    margin: 5rem 1rem 1rem;
  }

  .video__title-image {
    display: block;
    margin: auto;
    width: 70%;
  }

  .videos__container {
    grid-template-columns: repeat(2, 1fr);
    width: 82%;
    margin: auto;
  }

  .video__pleca {
    border-top: 5px solid var(--color-amarillo-variant);
    height: 3rem;
  }

  .marquee__video {
    font-size: 1.2rem;
  }

  .marquee__video-v {
    font-size: 1.2rem;
  }

  .marquee__video-v2 {
    font-size: 1.2rem;
  }
}

/*landscape*/
@media screen and (max-device-width: 1000px) and (orientation: landscape) {
  .video__container {
    grid-template-rows: repeat(2, 1fr);
    height: 100vh;
  }

  .video__title {
    margin: 2rem 3rem 0.5rem;
    height: 50%;
  }

  .video__title-image {
    width: 40%;
  }

  .videos__container {
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    width: 100vw;
    margin-left: auto;
    padding: 3rem;
  }

  .video__pleca {
    border-top: 5px solid var(--color-amarillo-variant);
    height: 3rem;
  }

  .marquee__video {
    font-size: 1.2rem;
  }

  .marquee_video-v {
    font-size: 1.2rem;
  }
  .marquee_video-v2 {
    font-size: 1.2rem;
  }
}
