.contact {
  background-color: var(--color-black);
  height: 100vh;
}

.contact__container {
  background-image: url("../../assets/gary_back.jpg");
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 100vh;
}

.contact__title {
  display: grid;
  height: 100%;
  place-items: center;
  margin: 4rem 3rem 1rem;
}

.contact__title-image {
  display: block;
  margin-left: auto;
}

.contact__caja {
  width: 800px;
  margin-left: auto;
  display: grid;
  grid-template-columns: 30% 67%;
  gap: 3%;
  padding: 3rem;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact__option {
  background-color: transparent;
  padding: 1rem;
  text-align: center;
  border: 1px solid var(--color-light-variant);
  transition: var(--transition);
}

.contact__option:hover {
  background: var(--color-black);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  color: var(--color-white);
  border: 1px solid var(--color-white);
  border-top: 6px solid var(--color-white);
}

.option-icon {
  font-size: 2rem;
}

.contact__option a {
  margin-top: 0.5rem;
  display: inline-block;
}

/* Form */

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 0.5rem;
}

input,
textarea {
  width: 100%;
  padding: 1rem;
  background-color: transparent;
  border: 1px solid var(--color-light-variant);
  resize: none;
  color: var(--color-white);
  font-size: 0.8rem;
  font-family: "Montserrat";
}

button {
  font-size: 0.9rem;
  font-family: "Montserrat";
}
/* Tablets */

@media screen and (max-width: 1024px) {
  .contact__title {
    height: 100%;
    margin: 4rem 2rem 1rem;
  }

  .contact__title-image {
    display: block;
    margin: auto;
    width: 70%;
  }

  .contact__caja {
    width: 95%;
    margin: 0 auto 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 5%;
  }

  .contact__options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}

/* Phones */

@media screen and (max-width: 600px) {
  .contact__container {
    height: 100vh;
  }

  .contact__title {
    margin: 5rem 1rem 0;
    padding: 0.5rem;
  }

  .contact__title-image {
    display: block;
    margin: auto;
    width: 85%;
  }

  .contact__caja {
    gap: 3%;
  }

  .contact__options {
    display: flex;
    flex-direction: row;
    gap: 3%;
    margin: auto;
  }

  .contact__option {
    height: 90%;
    font-size: 0.65rem;
    border: 1px solid var(--color-white);
  }

  .option-icon {
    font-size: 1.2rem;
  }

  input,
  textarea {
    padding: 0.7rem;
  }
  button {
    font-size: 0.7rem;
  }
}

/*landscape*/

@media screen and (max-device-width: 1000px) and (orientation: landscape) {
  .contact__container {
    height: 100vh;
  }

  .contact__title {
    margin: 2rem 3rem 0.6rem;

    height: 70%;
  }

  .contact__title-image {
    width: 40%;
    margin-right: 0.5rem;
  }

  .contact__option {
    height: 90%;
    font-size: 0;
    border: 1px solid var(--color-white);
  }

  .option-icon {
    font-size: 1.2rem;
  }

  form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    font-size: 0.5rem;
  }

  input {
    height: 40%;
    padding: 1rem;
  }

  textarea {
    padding: 0.2rem;
  }
  button {
    font-size: 0.7rem;
  }
}
